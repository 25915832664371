import Plausible from 'plausible-tracker';
var plausible = Plausible();
var Tracker = /** @class */ (function () {
    function Tracker() {
        this.initialize();
    }
    Tracker.prototype.initialize = function () {
        this.trackPageview();
        this.trackGoals();
        this.trackCheatSheetDownloads();
        this.trackSocialSharing();
    };
    Tracker.prototype.trackPageview = function () {
        if (this.isDevelopment()) {
            return;
        }
        plausible.trackPageview();
    };
    Tracker.prototype.trackGoals = function () {
        var _this = this;
        document.querySelectorAll('a[data-contact-option]').forEach(function (element) {
            element.addEventListener('click', _this.trackGoal.bind(_this));
            element.addEventListener('auxclick', _this.trackGoal.bind(_this));
        });
    };
    Tracker.prototype.trackGoal = function (event) {
        var anchorElement = this.getAnchorElement(event);
        var props = {
            type: anchorElement.dataset.contactOption,
        };
        this.trackEvent('Contact', { props: props });
    };
    Tracker.prototype.trackCheatSheetDownloads = function () {
        var _this = this;
        document.querySelectorAll('a[data-cheat-sheet]').forEach(function (element) {
            element.addEventListener('click', _this.trackCheatSheetDownload.bind(_this));
            element.addEventListener('auxclick', _this.trackCheatSheetDownload.bind(_this));
        });
    };
    Tracker.prototype.trackCheatSheetDownload = function (event) {
        var anchorElement = this.getAnchorElement(event);
        var props = {
            title: anchorElement.dataset.cheatSheet,
        };
        this.trackEvent('Download Cheat Sheet', { props: props });
    };
    Tracker.prototype.trackSocialSharing = function () {
        var _this = this;
        document.querySelectorAll('a[data-share-type]').forEach(function (element) {
            element.addEventListener('click', _this.trackSocialShare.bind(_this));
            element.addEventListener('auxclick', _this.trackSocialShare.bind(_this));
        });
    };
    Tracker.prototype.trackSocialShare = function (event) {
        var anchorElement = this.getAnchorElement(event);
        var props = {
            medium: anchorElement.dataset.shareMedium,
        };
        this.trackEvent('Share', { props: props });
    };
    /**
     * Resolve the anchor element from the event target.
     */
    Tracker.prototype.getAnchorElement = function (event) {
        var element = event.target;
        if (element.tagName === 'A') {
            return element;
        }
        return element.closest('a');
    };
    Tracker.prototype.trackEvent = function (eventName, options) {
        if (this.isDevelopment()) {
            console.log('Sending Plausible event', { eventName: eventName, options: options });
            return;
        }
        plausible.trackEvent(eventName, options);
    };
    Tracker.prototype.isDevelopment = function () {
        return location.hostname.endsWith('localhost');
    };
    return Tracker;
}());
export { Tracker };
